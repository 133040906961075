// import { zPayCreateCardForm } from "@zoox-framework/zoox-components";
import zPayCreateCardForm from "@/components/zPayCreateCardForm";
import { ApiClient } from "@zoox-framework/zoox-infra";
import CardService from "@/services/CardService";
import zPayConfirmationModal from "@/components/zPayConfirmationModal";
import PaymentService from "@/services/PaymentService";
export default {
  name: "FinishRegister",
  components: {
    "z-pay-create-card-form": zPayCreateCardForm,
    zPayConfirmationModal
  },
  props: {
    path: {
      type: String,
      default: "ListCards"
    }
  },
  data() {
    return {
      paymentService: new PaymentService(),
      cardService: new CardService(),
      success: false,
      apiClient: new ApiClient(),
      customerEmail: "",
      checkModal: false,
      card: null
    };
  },
  computed: {
    buttons() {
      return [
        { action: "accept", title: this.$t("CARD_ADD_CONFIRMATION.ACCEPT") },
        { action: "cancel", title: this.$t("CARD_ADD_CONFIRMATION.CANCEL") }
      ];
    },
    title() {
      return this.$t("CARD_ADD_CONFIRMATION.TITLE");
    },
    subtitle() {
      return this.$t("CARD_ADD_CONFIRMATION.SUBTITLE");
    },
    description() {
      return this.$t("CARD_ADD_CONFIRMATION.ACTION_DESCRIPTION");
    }
  },
  methods: {
    openCheckModal(card) {
      this.card = card;
      this.checkModal = true;
    },
    async submit(data) {
      const card = data.card;
      this.customerEmail = data.customer.email;
      const store = this.apiClient.getStore();
      const event = { showLoading: true };
      store.commit("loader/START_LOADING", event);
      const cards = await this.cardService.getCards();
      await this.cardService
        .createCard(card)
        .then(resp => {
          if (cards.find(x => x.gatewayCardId == resp.data.gatewayCardId)) {
            this.$toaster.error(this.$t("INFRA.PORTAL_CUSTOM_ERRORS.CARD_409"));
            this.$emit("error");
          } else {
            this.$toaster.success(this.$t("PAYMENT_SOLICITATION.CARD_ADDED"));
            this.$emit("success", resp.data);
          }
        })
        .catch(() => {
          this.$toaster.error(this.$t("GLOBAL.CREDIT_CARD.INVALID_CARD"));
        });

      store.commit("loader/FINISH_LOADING", event);
    },
    cancel() {
      this.checkModal = false;
      this.$emit("cancel");
    }
  }
};
