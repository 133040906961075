import Vue from "vue";
import {
  regionMixin,
  validationMixin,
  GeolocationService,
  CountryService,
  RegionService
} from "@zoox-framework/smck-service";
import moment from "moment";
import CompanyService from "@/services/CompanyService";
import { Helper } from "@zoox-framework/zoox-infra";
export default {
  name: "CreditCard",
  mixins: [regionMixin, validationMixin],
  props: {
    card: {
      type: Object,
      default: () => {
        return {
          number: null,
          name: null,
          cvv: null,
          exp: null,
          expMonth: null,
          expYear: null,
          brand: null,
          billingAddress: {
            number: null,
            street: null,
            neighborhood: null,
            line2: null,
            zipCode: null,
            city: null,
            state: null,
            country: null
          },
          customer: {
            email: null
          }
        };
      }
    }
  },
  data() {
    return {
      geoLocationService: new GeolocationService(),
      countryService: new CountryService(),
      regionService: new RegionService(),
      companyService: new CompanyService(),
      loadingButton: false,
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      years: [],
      menu: false,
      date: null,
      residenceCountryItems: [],
      zipItems: [],
      stateItems: [],
      searchResidenceCountry: null,
      searchZip: null,
      validateEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      onlyLetters: /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/,
      onlyNumber: /^[0-9]+$/,
      countryId: null,
      stateId: null,
      selectedState: null,
      requiredDocuments: {
        cpf: true,
        passport: true
      },
      confirmEmail: "",
      checkDate: [
        () =>
          (this.card.exp && moment(this.card.exp).isAfter(moment())) ||
          this.$t("GLOBAL.EXPIRATION_RULE_ERROR")
      ],
      onlyNumberRule: [
        v => (v && this.onlyNumber.test(v)) || this.$t("FORMERROR.NUMBER")
      ],
      onlyLettersRule: [
        v => this.onlyLetters.test(v) || this.$t("FORMERROR.LETTER")
      ],
      validateEmailRule: [
        v => this.validateEmail.test(v) || this.$t("GLOBAL_ERROR.EMAIL")
      ],
      required: [v => !!v || this.$t("FORMERROR.REQUIRED")],
      locale: ""
    };
  },
  async mounted() {
    const today = new Date();
    let year = today.getFullYear();
    for (let x = 0; x <= 10; x += 1) {
      this.years.push(year + x);
    }
    await this.findCountry("", "residenceCountryItems");

    if (localStorage.getItem("currentLang") != null) {
      let lang = localStorage.getItem("currentLang");
      this.locale = this.validateLocale(lang);
    }

    Vue.$globalEvent.$on("languageChanged", lang => {
      this.locale = this.validateLocale(lang);
    });
  },
  watch: {
    card: {
      immediate: true,
      deep: true,
      handler: function(value) {
        this.cardNameComputed = this.diacriticSensitiveRegex(value.name);
      }
    },
    searchResidenceCountry: function(value) {
      if (!this.preventCombosFill) {
        this.findCountry(value, "residenceCountryItems");
      }
    },
    searchZip: function(value) {
      if (
        value &&
        ((value.includes("-") && value.length >= 9) || value.length >= 8) &&
        !this.preventCombosFill
      ) {
        const zipRegex = /[0-9]{5}-[\d]{3}/;

        let searchTerm = "";

        // Formata a busca para API

        if (zipRegex.test(value)) {
          searchTerm = value;
        } else {
          if (value.includes("-")) {
            searchTerm = value;
          } else {
            searchTerm = value.substr(0, 5) + "-" + value.substr(5, 10);
          }
        }

        this.searchCountriesByZip(searchTerm);
      } else if (this.zipItems.length !== 0) {
        // Faz nada
      } else {
        this.zipItems = [];
      }
    }
  },
  methods: {
    normalizeCardEmail() {
      this.card.customer.email = this.card.customer.email
        .replace(/\s+/g, "")
        .toLowerCase();
    },
    validateUserEmailForm() {
      this.normalizeCardEmailConf();
      if (
        this.card.customer.email &&
        this.validateEmail.test(this.card.customer.email) &&
        this.confirmEmail == this.card.customer.email
      ) {
        return true;
      } else {
        return false;
      }
    },
    normalizeCardEmailConf() {
      this.confirmEmail = this.confirmEmail.replace(/\s+/g, "").toLowerCase();
    },
    validateLocale(lang) {
      let locale;
      if (lang === "uk") {
        locale = "en-GB";
      } else if (lang === "en") {
        locale = "en-US";
      } else if (lang === "pt") {
        locale = "pt-BR";
      } else if (lang === "es") {
        locale = "es-ES";
      } else {
        locale = "en-US";
      }
      return locale;
    },
    clear() {
      this.card.exp = null;
      this.date = null;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, year] = date.split("/");

      return `${year}-${month.padStart(2, "0")}`;
    },
    formatInputDate(date) {
      if (!date) return null;

      const [year, month] = date.split("-");
      return `${month}/${year}`;
    },

    save(date) {
      this.date = this.formatInputDate(this.card.exp);
      this.$refs.menu.save(date);
    },
    validateCardForm() {
      if (
        this.card.number &&
        this.card.cvv &&
        this.onlyLetters.test(this.card.name) &&
        this.checkCard(this.card.number) &&
        this.card.name &&
        this.card.exp &&
        moment(this.card.exp).isAfter(moment())
      ) {
        return true;
      } else {
        return false;
      }
    },
    validateBillingAddressForm() {
      if (
        this.card.billingAddress.zipCode &&
        this.card.billingAddress.street &&
        this.onlyNumber.test(this.card.billingAddress.number) &&
        this.card.billingAddress.number &&
        this.card.billingAddress.neighborhood &&
        this.card.billingAddress.city &&
        this.selectedState &&
        this.card.billingAddress.country
      ) {
        return true;
      } else {
        return false;
      }
    },
    validateAll() {
      return (
        this.validateCardForm() &&
        this.validateBillingAddressForm() &&
        this.validateUserEmailForm()
      );
    },
    diacriticSensitiveRegex(string = "") {
      let r = string.toLowerCase();
      r = r.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return r
        .replace(/[a,á,à,ä,ã,â]/g, "a")
        .replace(/[e,é,ë,è,ê]/g, "e")
        .replace(/[i,í,ï,î,ì]/g, "i")
        .replace(/[o,ó,ö,ò,õ,ô]/g, "o")
        .replace(/[u,ü,ú,ù,û]/g, "u")
        .replace(/[c,ç]/g, "c")
        .replace(/[n,ñ]/g, "n")
        .toUpperCase();
    },
    getDocumentMask: function(documentType) {
      switch (documentType) {
        case 1: // CPF
          return "###.###.###-##";
        case 5: // RUT
          return "#######-N";
        default:
          return "";
      }
    },
    getDocumentValidation: function(documentType, value) {
      let isValid = true;

      isValid = isValid && this.rules.required(value);

      switch (documentType) {
        case 1: // CPF
          isValid = isValid && this.rules.cpf(value);
          break;
        case 5: // RUT
          isValid = isValid && this.rules.rut(value);
          break;
        default:
          break;
      }

      return isValid;
    },
    findCountry: function(value, propertyPersist) {
      if (this.loading && !this.preventCombosFill) return;
      this.loading = true;

      return this.getCountries(value)
        .then(resp => {
          this[propertyPersist] = resp;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    residenceCountrySelect(data) {
      if (data && data.name && data.code) {
        this.card.billingAddress.country = data.code;
        this.selectedState = null;
        this.card.billingAddress.country = data.code;
        this.fillStates();
      }
    },
    filterItems(item, queryText, itemText) {
      if (queryText === null || queryText.length === 0) {
        return true;
      }
      return (
        Helper.normalizeString(itemText).indexOf(
          Helper.normalizeString(queryText)
        ) > -1
      );
    },
    searchCountriesByZip: function(filter = "", skipLoading = false) {
      const $context = this;

      if (
        !filter ||
        (!skipLoading && $context.loading && !this.preventCombosFill)
      )
        return;
      $context.loading = true;

      return $context.geoLocationService
        .geocodeAddress(
          filter,
          $context.card.billingAddress.country,
          $context.currentLang || ($context.$i18n && $context.$i18n.locale)
        )
        .then(resp => {
          if (resp.length === 0 && filter.length > 5) {
            $context.searchCountriesByZip(filter.substr(0, 5), true);
          } else {
            $context.zipItems = resp;
          }
        })
        .catch(err => {
          console.error(err);
          $context.zipItems = [];
        })
        .finally(() => {
          $context.loading = false;
        });
    },
    addZip() {
      if (this.card.billingAddress.zipCode !== this.searchZip) {
        this.card.billingAddress.number = "";
        this.card.billingAddress.line2 = "";

        this.card.billingAddress.address = "";
        this.card.billingAddress.neighborhood = "";
        this.card.billingAddress.zipCode = "";

        this.card.billingAddress.zipCode = this.searchZip;

        this.zipItems = this.zipItems.concat({
          zipCode: this.searchZip,
          value: this.searchZip
        });
      }
    },
    selectZip(data) {
      if (data) {
        this.card.billingAddress.zipCode = data.zipCode;
        this.card.billingAddress.street = data.route;
        this.card.billingAddress.neighborhood = data.neighborhood;
        this.card.billingAddress.city = data.cityName;

        if (data.country === "Brasil") {
          this.card.billingAddress.country = "BR";
          this.selectedState = data.stateId;
          this.fillStates();
        }

        if (data.complement) {
          this.card.billingAddress.line2 = data.complement;
        }
      }
    },
    fillStates() {
      return this.getStates("", this.card.billingAddress.country).then(data => {
        this.stateItems = data;
      });
    },
    clean: function() {
      this.card.number = null;
      this.card.name = null;
      this.card.cvv = null;
      this.card.exp = null;
      this.card.brand = null;
      this.card.billingAddress.number = null;
      this.card.billingAddress.street = null;
      this.card.billingAddress.neighborhood = null;
      this.card.billingAddress.line2 = null;
      this.card.billingAddress.zipCode = null;
      this.card.billingAddress.city = null;
      this.card.billingAddress.state = null;
      this.card.billingAddress.country = null;
    },
    validate: function() {
      return (
        this.card.number &&
        this.card.name &&
        this.card.cvv &&
        this.card.exp &&
        this.card.billingAddress.number &&
        this.card.billingAddress.street &&
        this.card.billingAddress.neighborhood &&
        this.card.billingAddress.zipCode &&
        this.card.billingAddress.city &&
        this.card.billingAddress.state &&
        this.card.billingAddress.country
      );
    },
    async callSubmit() {
      let expDate = moment(this.card.exp).format("YYYY-M");
      let exp = expDate.split("-");
      let expYear = parseInt(exp[0]);
      let expMonth = parseInt(exp[1]);

      if (this.selectedState && this.selectedState.length > 2) {
        const result = this.stateItems.filter(
          state => state.id === this.selectedState
        )[0];
        this.card.billingAddress.state = result.uf;
      } else {
        this.card.billingAddress.state = this.selectedState;
      }
      if (this.card.billingAddress.line2 === null) {
        this.card.billingAddress.line2 = "";
      }
      const card = {
        number: this.card.number,
        holderName: this.card.name,
        expMonth: expMonth,
        expYear: expYear,
        cvv: this.card.cvv,
        brand: this.card.brand,
        billingAddress: {
          line1: this.cardBillingAddressLine1,
          line2: this.card.billingAddress.line2,
          zipCode: this.card.billingAddress.zipCode,
          city: this.card.billingAddress.city,
          state: this.card.billingAddress.state.toUpperCase(),
          country: this.card.billingAddress.country
        },
        options: {
          verifyCard: true
        }
      };
      const customer = {
        customer: this.card.customer
      };
      await this.$emit("submit", { card: card, customer: customer });
    },
    checkCard(value) {
      let cardnumber = "";
      if (value != null) cardnumber = value;
      cardnumber = cardnumber.replace(/[^0-9]+/g, "");

      const cards = {
        Visa: /^4[0-9]{12}(?:[0-9]{3})/,
        Mastercard: /^5[1-5][0-9]{14}/,
        Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        Amex: /^3[47][0-9]{13}/,
        Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        Jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        Aura: /^(5078\d{2})(\d{2})(\d{11})$/
      };

      for (const flag in cards) {
        if (cards[flag].test(cardnumber)) {
          this.card.brand = flag;
          return true;
        }
      }

      return false;
    },
    getDocumentLength: function(documentType) {
      switch (documentType) {
        case 4: // Passport
          return 12;
        default:
          return 20;
      }
    },
    emailConfirmation(value) {
      if (value) {
        if (value === this.customer.email) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }
  },
  computed: {
    emailConfirmationRule() {
      return () =>
        this.card.customer.email === this.confirmEmail ||
        this.$t("REGISTRATION.EMAIL_MATCH");
    },
    cardNameComputed: {
      get: function() {
        return this.diacriticSensitiveRegex(this.card.name);
      },
      set: function(value) {
        this.card.name = this.diacriticSensitiveRegex(value);
      }
    },
    ruleCard: function() {
      return [
        val => this.rules.required(val),
        val => this.checkCard(val) || this.$t("GLOBAL.CREDIT_CARD.INVALID_CARD")
      ];
    },
    cardBillingAddressLine1: function() {
      let { number, street, neighborhood } = this.card.billingAddress;
      number = number ? number.replace(",", "") : number;
      street = street ? street.replace(",", "") : street;
      neighborhood = neighborhood
        ? neighborhood.replace(",", "")
        : neighborhood;

      return `${number}, ${street}, ${neighborhood}`;
    },
    getFlag: function() {
      const brand = this.getCardBrand || "";
      return require(`@/assets/images/${brand.toLowerCase()}.png`);
    },
    getCardBrand: function() {
      let cardnumber = "";
      if (this.card.number != null) cardnumber = this.card.number;
      cardnumber = cardnumber.replace(/[^0-9]+/g, "");

      const cards = {
        Visa: /^4[0-9]{12}(?:[0-9]{3})/,
        Mastercard: /^5[1-5][0-9]{14}/,
        Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        Amex: /^3[47][0-9]{13}/,
        Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        Jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        Aura: /^(5078\d{2})(\d{2})(\d{11})$/
      };

      for (const flag in cards) {
        if (cards[flag].test(cardnumber)) {
          this.card.brand = flag;
          return flag;
        }
      }

      return false;
    }
  }
};
